<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{
              operation != null ?
                ( operation === 'add' ? 'Add Lead' : ( operation === 'detail' ? 'Lead Detail'
                  : 'Edit Lead' ) ) : operationTitle
            }}
            <p><small>Manage and Assign Leads.</small></p>
          </template>
          <template v-if="operation === null" v-slot:toolbar>
            <b-input-group-append>
              <b-button :title="`Add New`"
                        @click="handleChangeOperations('add')"
                        class="btn btn-primary font-weight-bolder"
                        v-b-tooltip.hover
                        v-if="globalPropertyId > 0 && $global.hasPermission('leadsstore')"
                        variant="success"><i class="fa fa-plus fa-sm"></i> Add New
              </b-button>
              <b-button
                :class="checkFilterCount ? `btn btn-danger text-white font-weight-bolder ml-2` : 'btn btn-light-primary font-weight-bolder ml-2'"
                v-b-toggle.sidebar-backdrop-leads>
                <i class="fa fa-filter fa-sm"></i> Filter
                <b-badge class="badge-light filter" fill v-if="checkFilterCount">
                  {{ checkFilterCount }}
                </b-badge>
              </b-button>

            </b-input-group-append>
          </template>
          <template v-else v-slot:toolbar>
            <b-button :disabled="global.pendingRequests > 0" @click="handleSubmitOperation"
                      class="mr-2 mt-3"
                      size="sm"
                      variant="primary"
            >
              <i class="fa fa-spin fa-spinner"
                 v-show="global.pendingRequests > 0"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button @click="afterCloseOperation()" class="mt-3" size="sm"
                      variant="danger">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>

          <template v-slot:body>
            <v-card>
              <div class="company-profiles-table"
                   v-if="$global.hasPermission('leadsview') && operation === null">

                <div class="w-100 mb-6">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length"><label>Show
                        <b-form-select :options="[10, 50, 100]"
                                       class="datatable-select"
                                       size="sm"
                                       v-model="pagination.totalPerPage"></b-form-select>
                        entries</label></div>

                    </div>
                    <!--                                        <div class="col-sm-12 col-md-6 mt-2">-->
                    <!--                                            <span v-for="(value,key) in filters"-->
                    <!--                                                  :key="key">-->
                    <!--                                                <span v-if="_.isArray(value) && key !== 'visible'">-->
                    <!--                                                    <b-tag class="py-2 text-capitalize" v-if="value.length > 0"> {{$global.removeSymbols(key)}}-->
                    <!--                                                        <i class="fa fa-times-circle ml-2"-->
                    <!--                                                           @click="clearFilter(key, [])"></i>-->
                    <!--                                                    </b-tag>-->
                    <!--                                                </span>-->
                    <!--                                                <span v-else>-->
                    <!--                                                    <b-tag class="py-2"-->
                    <!--                                                           v-if="value && key !== 'visible'"> <span-->
                    <!--                                                        class="text-capitalize">{{$global.removeSymbols(key)}}</span>-->
                    <!--                                                    <i class="fa fa-times-circle ml-2"-->
                    <!--                                                       @click="clearFilter(key , null)"></i>-->
                    <!--                                                    </b-tag>-->
                    <!--                                                </span>-->
                    <!--                                            </span>-->
                    <!--                                        </div>-->
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_filter" id="kt_datatable_filter"><label>Search:
                        <b-form-input class="form-control form-control-sm ml-2 w-25"
                                      label="Search"
                                      type="search"
                                      v-model="search"
                                      v-on:keyup.enter="handleSearch"></b-form-input>
                      </label>
                      </div>
                    </div>
                  </div>
                </div>

                <v-data-table
                  :headers="columns"
                  :items="dataSource"
                  :items-per-page="parseInt(pagination.totalPerPage)"
                  :loading="loading"
                  class="table table-head-custom table-vertical-center table-responsive table-responsive"
                  hide-default-footer
                  ref="table"
                  responsive
                >
                  <template v-slot:item.id="record">
                    {{ dataSource.length - record.index }}
                  </template>
                  <template v-slot:item.first_name="record">
                    {{ record.item.title }}
                  </template>
                  <template v-slot:item.sources="record">
                    {{ record.item.sources ? record.item.sources.source_name : "" }}
                  </template>
                  <template v-slot:item.created_at="record">
                    {{ $global.dateConvert(record.item.created_at) }}
                  </template>
                  <template class="action-column" v-slot:item.action="record">
                    <a @click="handleChangeOperations('edit', record.item.id)"
                       class="btn btn-icon btn-light btn-hover-primary btn-sm"
                       size="sm"
                       v-if="$global.hasPermission('leadsupdate')">
                                            <span
                                              class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                class="action-edit-button"
                                                src="/media/svg/icons/Communication/Write.svg"
                                              ></inline-svg>
                                              <!--end::Svg Icon-->
                                            </span>
                    </a>
                  </template>
                </v-data-table>
                <div class="row">
                  <div class="pagination-box">
                    <b-pagination
                      :per-page="pagination.totalPerPage"
                      :total-rows="pagination.total"
                      size="lg"
                      v-model="pagination.current"
                    ></b-pagination>

                  </div>
                </div><!-- /.pull-right -->
              </div>
              <div v-show="operation !== null && operation !== 'show'">
                <div class="leads-operation">
                  <b-container fluid>
                    <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                      <b-row>
                        <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
                          <b-row>
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('mobile_number')"
                                label="Mobile Number *"
                                label-for="mobile_number"
                              >
                                <!--                                                            <treeselect v-if="!status"-->
                                <!--                                                                        id="mobile_number" v-numericOnly.keyup-->
                                <!--                                                                        label="mobile_number"-->
                                <!--                                                                        :multiple="false"-->
                                <!--                                                                        :options="dropdowns.mobiles"-->
                                <!--                                                                        v-model="formFields.mobile_number"-->
                                <!--                                                                        @input="handleSelectMobileUserDetail($event)"-->
                                <!--                                                                        :class="[{'invalid is-invalid': (formErrors.has('mobile_number'))}]"-->
                                <!--                                                            />-->
                                <b-form-input
                                  :class="[{'invalid is-invalid': (formErrors.has('mobile_number'))}]"
                                  id="mobile_number"
                                  label="mobile_number"
                                  v-model="formFields.mobile_number"
                                  v-numericOnly.keyup
                                />

                                <!--                                                            <b-form-checkbox-->
                                <!--                                                                v-model="status">-->
                                <!--                                                                ManuallyAdd-->
                                <!--                                                            </b-form-checkbox>-->
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('first_name')"
                                label="First Name *"
                                label-for="first_name"
                              >
                                <b-form-input
                                  :state="((formErrors.has('first_name') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="first_name"
                                  type="text"
                                  v-model="formFields.first_name"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('last_name')"
                                label="Last Name *"
                                label-for="last_name"
                              >
                                <b-form-input
                                  :state="((formErrors.has('last_name') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="last_name"
                                  type="text"
                                  v-model="formFields.last_name"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row><!--/b-row-->
                          <b-row>
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('email')"
                                label="Email"
                                label-for="email"
                              >
                                <b-form-input
                                  :state="((formErrors.has('email') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="email"
                                  type="email"
                                  v-model="formFields.email"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('alternate_mobile_number')"
                                label="Alternate Mobile Number"
                                label-for="alternate_mobile_number"
                              >
                                <b-form-input
                                  :state="((formErrors.has('alternate_mobile_number') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="alternate_mobile_number"
                                  type="text"
                                  v-model="formFields.alternate_mobile_number"
                                  v-numericOnly.keyup
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('asset_id')"
                                label="Source"
                                label-for="asset_id"
                              >
                                <b-form-select
                                  :options=" dropdowns.sources"
                                  :state="((formErrors.has('asset_id') ? false : null))"
                                  v-model="formFields.asset_id"
                                >
                                </b-form-select>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row>
                          <b-row>
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('gst_no')"
                                label="GST No."
                                label-for="gst_no"
                              >
                                <b-form-input
                                  :state="((formErrors.has('gst_no') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="gst_no"
                                  type="text"
                                  v-model="formFields.gst_no"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('pan_no')"
                                label="PAN No."
                                label-for="pan_no"
                              >
                                <b-form-input
                                  :state="((formErrors.has('pan_no') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="pan_no"
                                  type="text"
                                  v-model="formFields.pan_no"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('country_id')"
                                label="Country"
                                label-for="country_id"
                              >
                                <b-form-select
                                  :options="dropdowns.countries"
                                  :state="((formErrors.has('country_id') ? false : null))"
                                  v-model="formFields.country_id"
                                >
                                </b-form-select>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row>
                          <b-row>
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('state_id')"
                                label="States"
                                label-for="state_id"
                              >
                                <b-form-select
                                  :options="_.filter(dropdowns.states,(i) => i.country_id === formFields.country_id)"
                                  :state="((formErrors.has('state_id') ? false : null))"
                                  v-model="formFields.state_id"
                                >
                                </b-form-select>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('city')"
                                label="City"
                                label-for="city"
                              >
                                <b-form-input
                                  :state="((formErrors.has('city') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="city"
                                  type="text"
                                  v-model="formFields.city"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row>
                          <b-row>
                            <b-col sm="12">
                              <b-form-group
                                :invalid-feedback="formErrors.first('address')"
                                label="Address"
                                label-for="address"
                              >
                                <b-form-textarea
                                  :state="((formErrors.has('address') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="address"
                                  type="text"
                                  v-model="formFields.address"
                                ></b-form-textarea>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row>
                          <b-row>
                            <b-col sm="12">
                              <b-form-group
                                :invalid-feedback="formErrors.first('note')"
                                label="Note"
                                label-for="note"
                              >
                                <b-form-textarea
                                  :state="((formErrors.has('note') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="note"
                                  type="text"
                                  v-model="formFields.note"
                                ></b-form-textarea>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row>
                          <hr />
                          <b-row class="operation-footer">
                            <b-col sm="12">
                              <b-button
                                :disabled="global.pendingRequests > 0"
                                size="sm"
                                type="submit"
                                variant="primary"
                              >
                                <i class="fa fa-spin fa-spinner"
                                   v-show="global.pendingRequests > 0"></i>
                                <i class="fa fa-save fa-sm"></i>
                                Save
                              </b-button>
                              <b-button @click="afterCloseOperation()" class="ml-3"
                                        size="sm"
                                        variant="danger">
                                <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col><!--/b-col-->
                      </b-row><!--/b-row-->
                    </form><!--/form-->
                  </b-container><!--/b-container-->
                </div><!--/.leads-operation-->
              </div>
            </v-card>
          </template>
        </KTCard>

        <template>
          <div>
            <b-sidebar
              :backdrop-variant="`transparent`"
              backdrop
              id="sidebar-backdrop-leads"
              right
              shadow
              title="Advance Filters"
            >
              <template #header="{ hide }">
                <b-col sm="10">
                  <strong id="sidebar-backdrop-leads___title__">Filters</strong>
                </b-col>
                <b-col sm="2">
                  <button @click="hide();"
                          class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
                    <i class="ki ki-close icon-xs text-muted"></i>
                  </button>
                </b-col>
              </template>
              <template #footer="{ hide }">
                <div
                  class="d-flex justify-center cursor-pointer w-full border-top align-items-center px-3 py-4">
                  <b-button @click="hide();handleResetFilterClick()"
                            class="btn btn-light-primary font-weight-bolder ml-2">
                    <i class="fa fa-broom fa-sm"></i> Clear All
                  </b-button>
                </div>
              </template>
              <div class="px-10 py-4">
                <b-col sm="12">
                  <b-form-group
                    label="Lead Start Date"
                    label-for="from_date">
                    <b-form-datepicker
                      :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                      :reset-value="''"
                      close-button
                      reset-button
                      today-button
                      v-model="filters.from_date"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Lead End Date"
                    label-for="to_date">
                    <b-form-datepicker
                      :date-disabled-fn="dateDisabled"
                      :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                      :disabled="!filters.from_date"
                      close-button
                      reset-button
                      today-button
                      v-model="filters.to_date"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Source"
                    label-for="filter-source">
                    <b-form-select :options="dropdowns.sources"
                                   class="mb-2"
                                   id="filter-source"
                                   v-model="filters.source"></b-form-select>
                  </b-form-group>
                </b-col><!--/b-col-->
              </div>
            </b-sidebar>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import KTCard from "@/view/content/Card.vue";
import ListingMixin from "@/assets/js/components/ListingMixin";
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { getPropertyId, getAuthUser } from "@/util/globalFunction";

const FORM_STATE = {
  first_name: null,
  last_name: null,
  email: null,
  mobile_number: null,
  alternate_mobile_number: null,
  sources: undefined,
  country_id: 101,
  state_id: undefined,
  asset_id: undefined,
  user_id: undefined,
  _method: 'post',
  pan_no: null,
  gst_no: null,
  status: false,
};

const DEFAULT_FILTER_STATE = {
  source: null,
  client_budget: null,
  user_id: null,
  lead_status: null,
  from_date: null,
  to_date: null,
};

const COLUMN_DEFINATION = (self) => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Lead Name",
    sortable: false,
    value: 'first_name',
  },
  {
    text: "Mobile Number",
    sortable: false,
    value: 'mobile_number',
  },
  {
    text: "Lead Source",
    sortable: false,
    value: 'sources',
  },
  {
    text: "City",
    sortable: false,
    value: 'city',
  },
  ( !self.$global.parentUserExist()
    ? {
      text: "No. of Events",
      value: "event_count"
    } : {} ),
  {
    text: "Created At",
    sortable: false,
    value: 'created_at',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },
];

export default {
  mixins: [ListingMixin],
  data() {
    return {
      operationTitle: 'Leads',
      formFields: { ...FORM_STATE },
      filters: { ...DEFAULT_FILTER_STATE },
      listUrl: '/leads',
      columns: COLUMN_DEFINATION(this),

      dropdowns: {
        sources: [],
        users: [],
        states: [],
        mobiles: [],
        countries: [],
        status: [
          { value: 'Hot', text: 'Hot' },
          { value: 'Standard', text: 'Standard' },
          { value: 'Qualified', text: 'Qualified' },
          { value: 'Won', text: 'Won' },
          { value: 'Lost', text: 'Lost' },
        ]
      },
      status: false,
    };
  },
  components: {
    KTCard
  },
  mounted() {
    this.getSources();
    this.getStates();
    this.getUserMobileNumber();
    this.getCountries();
    if ( this.operation == "add" ) {
      if ( localStorage.getItem("lead-create") ) {
        let data = JSON.parse(localStorage.getItem("lead-create"));
        let name = data.name.split(" ");
        this.formFields.mobile_number = data.phone;
        this.formFields.email = data.email;
        this.formFields.first_name = name[0];
        this.formFields.last_name = name[1];
      }
    }
    if ( this.$route.query && this.$route.query.operation && this.$route.query.id ) {
      this.handleEditOperation(this.$route.query.id);
    }
  },
  methods: {
    dateDisabled(ymd, date) {
      var myCurrentDate = new Date(date);
      var myPastDate = new Date(myCurrentDate);
      myPastDate.setDate(myPastDate.getDate() + 1);

      return ( myPastDate < new Date(this.filters.from_date) );
    },
    async handleSelectMobileUserDetail(id) {
      try {
        const response = await request({
          url: `/dropdowns/user/${ id }`,
          method: 'post',
        });

        const { data } = response.data;
        if ( data ) {
          let name = data.name.split(" ");
          this.formFields.email = data.email;
          this.formFields.first_name = name[0];
          this.formFields.last_name = name[1];
        }
      } catch (e) {

      }
    },
    afterCloseOperation() {
      this.formFields = { ...FORM_STATE };
      this.$router.push({ name: 'events' });
    },
    handleChangeOperations(operation = 'add', operationDataId = null) {
      this.$router.replace({
        query: Object.assign({},
          this.$route.query,
          {
            ...this.listQueryParams,
            operation: operation,
            id: operationDataId,
          },
        ),
      });
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      try {
        const response = await request({
          url: this.formFields.id ? 'leads/update' : 'leads/create',
          method: 'post',
          data: this.formFields,
        });
        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        localStorage.removeItem("lead-create");
        this.afterCloseOperation();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    async handleDeleteOperation(id) {
      let response = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( response ) {
        try {
          const response = await request({
            method: 'post',
            url: '/leads/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/leads/detail/${ id }`,
        });
        const { data } = response.data;
        this.formFields = {
          ...data,
          asset_id: data.sources ? data.sources.id : undefined,
          property_id: data.property ? data.property.id : undefined,
        };
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...FORM_STATE };
      }
    },
    async getSources() {
      try {
        const response = await request({
          url: '/dropdowns/sources',
          method: 'post',
          data: {
            filter: {
              property_id: getPropertyId()
            }
          }
        });

        const { data } = response.data;
        this.dropdowns.sources = data.map((item) => {
          return {
            value: item.id,
            text: item.source_name,
          };
        });
      } catch (e) {

      }
    },
    async getStates() {
      try {
        const response = await request({
          url: '/dropdowns/states',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.states = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
            country_id: item.country_id
          };
        });
      } catch (e) {

      }
    },
    async getCountries() {
      try {
        const response = await request({
          url: '/dropdowns/countries',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.countries = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getUserMobileNumber() {
      try {
        const response = await request({
          url: `/dropdowns/user/mobile/number`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.mobiles = data.map((item) => {
          return {
            id: item.phone,
            label: item.phone
          };
        });
      } catch (e) {

      }
    },
    handleResetFilterClick() {
      this.filters = { ...DEFAULT_FILTER_STATE };
      this.isFilterApplied = 'reset';
      this.checkFilterCountStatus();
    },
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
  watch: {
    'status': function (nVal, oVal) {
      if ( nVal ) {
        this.formFields.mobile_number = null;
      } else {
        this.formFields.mobile_number = undefined;
      }
    }
  }
};
</script>
